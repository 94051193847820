import '@tensorflow/tfjs-backend-webgl';
import {getDatabase, ref, push, set, onValue, orderByChild, limitToLast, query} from 'firebase/database';

import * as mpPose from '@mediapipe/pose';
import * as tf from '@tensorflow/tfjs-core';

import * as posedetection from '@tensorflow-models/pose-detection';

import {Camera} from './camera';
import {RendererCanvas2d} from './renderer_canvas2d';
import {STATE} from './params';
import $ from 'jquery';

let detector, camera;
let renderer = null;
let rafId;

const database = getDatabase(STATE.app);
const scoresListRef = ref(database, 'scores');

async function createDetector() {
  return posedetection.createDetector(posedetection.SupportedModels.BlazePose, {
    runtime: 'mediapipe',
    solutionPath:
        `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`,
  });
};

async function renderResult() {
  if (camera.video.readyState < 2) {
    await new Promise((resolve) => {
      camera.video.onloadeddata = () => {
        resolve(video);
      };
    });
  }

  let poses = null;

  poses = await detector.estimatePoses(
      camera.video,
      {
        flipHorizontal: false,
      }
    );

  const rendererParams = [camera.video, poses];
  renderer.draw(rendererParams);
}

async function renderPrediction() {
  await renderResult();

  rafId = requestAnimationFrame(renderPrediction);
};

async function getHighScores() {
  const firebaseQuery = query(scoresListRef, limitToLast(15), orderByChild('score'));
  onValue(firebaseQuery, (snapshot) => {
    let data = snapshot.val();
    STATE.highscores = Object.values(data).sort((a, b) => b.score - a.score);
    rebuildHighscoreTable();
  });
}

function rebuildHighscoreTable() {
  const $container = $('.highscore-container');

  $container.empty();
  STATE.highscores.forEach((score) => {
    $container.append("<div class='score'><span class='player'>"+ score.nickname +"</span>: <span class='score'>"+ score.score +"</span></div>")
  });
};

function listenToPushScore() {
  document.addEventListener('game-over', (event) => {
    const $nick = $('#nickname').val();
    const score = parseInt(event.detail.score);
    if (!$nick || score == 0) return;
    const when = new Date();

    const newScoreRef = push(scoresListRef);
    set(newScoreRef, {
      nickname: $nick,
      score: score,
      time: when,
    });
  });
}

function listenToStopMusic() {
  document.addEventListener('game-over', () => {
    STATE.audioElement.pause();
    STATE.audioElement.currentTime = 0;
  });
}

function listenToStartMusic() {
  document.addEventListener('game-start', () => {
    STATE.audioElement.play();
  });
}

async function app() {
  camera = await Camera.setup(STATE.camera);

  await tf.ready();
  detector = await createDetector();
  const canvas = document.getElementById('output');
  canvas.width = camera.video.width;
  canvas.height = camera.video.height;
  renderer = new RendererCanvas2d(canvas);
  getHighScores();
  listenToPushScore();
  listenToStartMusic();
  listenToStopMusic();
  renderPrediction();
};

app();
